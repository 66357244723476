import type { RecursivePartial } from '#types/utils'
import type { CartConfig } from '#types/config/components/cart'

export default {
  brandClasses: {
    header: 'mb-0 md:mb-4 lg:mb-6',
    headerTitle: 'subtitle-2',
    headerTotalItems: 'fw-bold flex items-center justify-end <md:text-sm',
    itemsPerShipping: 'md:mb-4 b-grey-50'
  },
  cartItem: {
    thumbnails: {
      width: { sm: 94, lg: 128 },
      height: { sm: 94, lg: 128 }
    }
  },
  item: {
    links: 'text-sm'
  },
  orderSummary: {
    header: 'text-md fw-bold',
    subtotal: 'text-sm',
    total: 'uppercase text-sm fw-bold'
  }
} satisfies RecursivePartial<CartConfig>
