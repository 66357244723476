import type { CartConfig } from '#types/config/components/cart'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    floatingCta: (expanded: boolean) => `ml-4 ${expanded ? 'bg-brand-2 p-4' : 'b-grey-10'}`
  },
  showCheckoutCta: false,
  stickyCtas: {
    enabled: false
  }
} satisfies RecursivePartial<CartConfig>
